/* HTML Components */
.double-textField {
  display: flex;
  gap: 10px;
}
.format-label {
  text-align: initial !important;
}
.modal-section-title{
  position: relative;
  right: 25px;
}

@media screen and (min-width: 0px) and (max-width: 599px) {
  .double-textField {
    display: block;
  }
}

.FAQ-settings-container {
    width: 50vw !important;
    padding: 40px;
}
.FAQ-settings-input-container {
    display: flex;
    gap: 50px;
    margin-top: 15px;
    width: 100%;
}

/* loader */
.center-loading-icon {
    margin-top: 80px;
}

/* text */
.text--center {
    text-align: center;
}
.text--right {
    text-align: right;
}

/* slider range */
.settings-FAQ__range-values {
    margin-top: 15px;
}
.rc-slider {
    margin: 20px 20px 0 20px;
}

/*  */
.settings-kb {
    margin-top: 25px;
    margin-bottom: 10px;
}

.settings-kb__btn-row {
    display: flex;
    justify-content: flex-end;
}

/* table */
.settings-kb__table {
    margin-top: 15px;
}

.settings-kb__table-body:last-of-type > .table-row > .table-cell {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.settings-kb__table-head {
    display: flex;
    background-color: #27315d;
    color: white;
    font-weight: 500;
    font-size: 0.95rem;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 5px;
    margin-right: 25px;
}

.settings-kb__table-body {
    display: flex;
}

.settings-kb__table-body.empty {
    margin-right: 25px;
}
.table-row {
    display: flex;
    width: 100%;
}
.table-cell {
    border: 1px #b2b2b2 solid;
    padding: 5px 10px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.table-cell > p {
    margin: 5px 0;
}

.dialog-actions-steps {
    margin: 0;
    padding: 16px;
    justify-content: center;
    display: flex;
    gap: 10px;
    bottom: 0px;
    position: fixed;
    background: white;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    overflow: hidden;
    left: 0;
}

@media only screen and (min-width: 1700px) {
    .FAQ-settings-container {
        width: 40vw !important;
    }
}

@media only screen and (max-width: 700px) {
    .FAQ-settings-container {
        width: 80vw !important;
    }
}

.intent-modal-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.intent-table-example-component {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 15px 0;
    padding: 15px 0;
    font-size: 14px;
    font-weight: 400;
}
.test-results-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.intent-example-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.intent-example-main-container {
    width: 100%;
}
.intent-example-utility {
    display: flex;
}
.intent-example-utility label {
    border-bottom: 1px solid #27315d;
}
.straight-line {
    width: 100%;
    height: 2px;
    margin-top: 10px;
    margin-bottom: 2px;
}

.intent-word-example {
    padding: 2.5px;
}

.selected-intent-word-example {
    padding: 2.5px;
    background-color: #70c5d8;
}

.example-label-container-md {
    min-height: 150px;
}

.example-label-container-lg {
    min-height: 250px;
}

.example-label-container-xl {
    min-height: 350px;
}

.line-container {
    display: flex;
    align-items: flex-end;
    width: 100%;
}

/* create a class that has the style of a square */
.square {
    height: 5px;
    width: 5px;
    border-radius: 0%;
    display: inline-block;
}

.entity-label-container {
    position: relative;
    padding: 5px 0;
}

/* Labels Line Colors */

.blue-tag-color {
    background-color: #70c5d8;
}

.red-tag-color {
    background-color: #e34c32;
}

.green-tag-color {
    background-color: #5fb0b5;
}

.pink-tag-color {
    background-color: #e61f9a;
}

.purple-tag-color {
    background-color: #baaee8;
}

/* dashed background-color */
.dashed-blue-tag-color {
    background-image: linear-gradient(90deg, #70c5d8 50%, transparent 50%);
    background-size: 5px 5px;
}

.dashed-red-tag-color {
    background-image: linear-gradient(90deg, #e34c32 50%, transparent 50%);
    background-size: 5px 5px;
}

.dashed-green-tag-color {
    background-image: linear-gradient(90deg, #5fb0b5 50%, transparent 50%);
    background-size: 5px 5px;
}

.dashed-pink-tag-color {
    background-image: linear-gradient(90deg, #e61f9a 50%, transparent 50%);
    background-size: 5px 5px;
}

.dashed-purple-tag-color {
    background-image: linear-gradient(90deg, #baaee8 50%, transparent 50%);
    background-size: 5px 5px;
}

.entity-label-container-second {
    position: relative;
    margin-top: 10px;
    padding: 5px 0;
}

.entity-example-label {
    position: absolute;
    font-size: 11px;
    font-weight: 700;
    /* fix text overlapping */
    color: white;
    padding: 2px 5px;
    border-radius: 5px;
    max-width: 82px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
}

.entity-example-label span {
    white-space: nowrap;
}

.entity-example-label span:hover {
    width: auto;
    overflow: visible;
    text-overflow: clip;
}

.test-intent-result {
    display: flex;
    flex-direction: column;
}

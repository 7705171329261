.edit-qna-main :is(h1, h2, h3, h4, h5, h6) {
    margin: 0;
}

.edit-qna-header :is(h1, h2, h3, h4, h5, h6) {
    margin: 0;
}

.edit-qna-main {
    margin-top: 15px;
}

.edit-qna-container {
    width: 50vw !important;
    margin: 40px;
}

.edit-qna-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}
.edit-qna-header-search-container {
    display: flex;
    width: 100%;
    align-items: center;
}
.edit-suggestions-header {
    display: flex;
    width: 100%;
    align-items: center;
    margin: 10px 0;
}

.pairs-header {
    display: flex;
    background-color: #27315d;
    color: white;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 5px;
}

.edit-pairs-container {
    display: flex;
    align-items: flex-start;
}

.qna-pair {
    display: flex;
    width: auto;
}
.edit-question {
    border: 1px #b2b2b2 solid;
    padding: 10px;
    width: 25vw;
}

.questions-textfields {
    display: flex;
}

.edit-response {
    border: 1px #b2b2b2 solid;
    padding: 10px;
    width: 25vw;
}

.pagination-container {
    /* margin: 10px 0; */
    justify-content: center;
    align-items: center;
}

.source-file-header {
    border: 1px #b2b2b2 solid;
    border-bottom: none;
    display: flex;
    justify-content: center;
}

.response-textfield {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}

.uploadFile-container {
    display: flex;
    align-items: flex-start;
    gap: 2px;
}

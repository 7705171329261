.entities-name-header{
    width: 50%;
    border-right: 1px solid #fafafa;
    padding: 5px;
    word-break: break-all;
}

.entities-type-header{
    width: 50%;
    padding: 5px;
    word-break: break-all;
}

/* change the font size for the media queries of 960 and 600 */
@media (max-width: 960px) {
    .entities-name-header{
        font-size: 14px;
    }
    .entities-type-header{
        font-size: 14px;
    }
}

@media (max-width: 600px) {
    .entities-name-header{
        font-size: 12px;
    }
    .entities-type-header{
        font-size: 12px;
    }
}

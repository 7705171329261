body {
  overflow-x: hidden;
}

.hide-overflow {
  overflow-y: hidden;
}

.swal2-container {
  z-index: 9999 !important;
}

hr {
  width: 100%;
}
.main-entity-modal{
}

.example-description-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.example-description-container img {
    width: 70%;
}

.accordion-container{
}

.MuiAccordionSummary-content{
    justify-content: space-between;
}

.MuiAccordionDetails-root{
    flex-direction: column;
}

/* loader */
.center-loading-icon {
    margin-top: 80px;
}

/* table */
.settings-kb__table {
    margin-top: 15px;
}

.settings-kb__table-head {
    display: flex;
    background-color: #27315d;
    color: white;
    font-weight: 500;
    font-size: 0.95rem;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 5px;
    margin-right: 25px;
}

.settings-kb__table-body {
    display: flex;
}

.settings-kb__table-body.empty {
    margin-right: 25px;
}

.settings-kb__table-body:last-of-type > .table-row > .table-cell {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.table-row {
    display: flex;
    width: 100%;
}

.table-cell {
    border: 1px #b2b2b2 solid;
    padding: 5px 10px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-cell > p {
    margin: 5px 0;
}

.settings-kb__btn-row {
    display: flex;
    justify-content: flex-end;
}

.genai-main {
    width: 60vw !important;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 20px 80px;
}

.new-template-input-container {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    gap: 15px;
}
.new-template-input {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.template-content-lenght-container {
    display: flex;
    justify-content: flex-end;
}

.template-content-lenght-number {
    position: relative;
    top: 10px;
    right: 33px;
    font-size: 13px;
}

.template-name-lenght-number {
    position: relative;
    top: 10px;
    right: 29px;
    font-size: 13px;
}

.templateContent textarea {
    min-height: 100px !important;
}

.check-circle-icon {
    color: #44ab3f;
    font-size: 50px !important;
}
.error-circle-icon {
    color: #e42922;
    font-size: 50px !important;
}

.templateActionsContainer {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
}

.templateContentHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.emojiPickerMenu {
    position: absolute;
    width: min-content;
    top: 5px;
    right: 400px;
    z-index: 1000;
}

.buttonsFooter {
    width: 100%;
}

.buttonsContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    width: 100%;
}

.buttonsContent {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

.upload-templateFile-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
}

.dropZoneContainerTemplate {
    min-height: min-content !important;
    padding: 5px;
    background-color: #fafafa !important;
}

.dropZoneContainerTemplateError {
    border-color: red !important;
}

.dropZoneContainer {
    min-height: min-content !important;
    width: 250px !important;
    padding: 5px;
    background-color: #fafafa !important;
}
.dropZoneContainerNoWidth {
    min-height: min-content !important;
    padding: 5px;
    background-color: #fafafa !important;
}
.dropZoneContainerWithError {
    min-height: min-content !important;
    width: 250px !important;
    padding: 5px;
    border-color: red !important;
}
.dropZoneContainerWithErrorNoWidth {
    min-height: min-content !important;
    padding: 5px;
    border-color: red !important;
}
.previewGridContainer {
    justify-content: center;
}
.previewGridItem {
    padding: 35px 0 !important;
}
.previewGridItem button {
    display: none !important;
}
.previewGridImage {
    padding: 0;
}
.dropzoneParagraph {
    padding: 0 !important;
    margin: 0 !important;
    font-size: 1.05rem !important;
    color: #8b91a8 !important;
}

.MuiDropzoneArea-icon {
    /* display: none !important; */
    width: 35px !important;
    height: 35px !important;
    color: #27315d !important;
}

.messageMultimedia__tableContent {
    display: flex;
    gap: 10px;
    margin: 10px 0;
    align-items: flex-start;
}

.messagesForm__title-left {
    position: relative;
    right: 115px;
}

.messagesForm__title-right {
    position: relative;
    left: 30px;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
    .dropZoneContainer {
        width: 25vw !important;
    }
    .dropZoneContainerWithError {
        width: 25vw !important;
    }

    .messageMultimedia__tableContent {
        gap: 2px;
    }

    .messageMultimedia__triggerField {
        width: 25vw !important;
    }

    .mentions--field.mentions--field--multiLine > .mentions--field__control {
        width: 25vw !important;
    }

    .MuiDropzonePreviewList-image {
        height: 9vh !important;
    }

    .messagesForm__title-left {
        right: 20vw;
    }
    .messagesForm__title-right {
        left: 50px;
    }

    .MuiDropzonePreviewList-imageContainer > p {
        font-size: 0.85rem;
    }

    .MuiDropzonePreviewList-imageContainer {
        max-width: 50% !important;
    }
}

.preview-csv-grid{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.source-selector-container{
    margin: 10px;
}

.contacts-table-container{
    display: flex;
    justify-content: center;
    height: 320px;
    width: 100%;
}

.contacts-table{
    width: 80%;
}

.drop-csv-container{
    min-height: min-content !important;
    max-width: 80%
}

.preview-csv-grid button {
    display: none !important;
}

.form-header{
    display: flex;
    justify-content: space-between;
}

.center-drop-zone{
    display: flex;
    justify-content: center;
}
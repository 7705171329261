.apps-table-header{
    display: flex;
    background-color: #27315d;
    color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.app-name-header{
    width: 50%;
    border-right: 1px solid #fafafa;
    padding: 5px;
}

.app-culture-header{
    width: 50%;
    padding: 5px;
}

.apps-table{
    width: 100%;
}

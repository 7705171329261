.top-label-field {
  text-align: left !important;
  margin-top: 10px;
}

@media only screen and (max-width: 600px) {
  .top-left-label {
    position: relative;
    right: 0;
  }
}
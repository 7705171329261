.list-entity-header{
    display: flex;
    background-color: #27315d;
    color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.list-entity-delete-row{
    position: absolute;
    right: -30px;
}

.list-entity-key-header{
    padding: 0px 14px;
    width: 180px;
}

.list-entity-values-header{
    padding: 0px 14px;
}

.list-entity-row{
    position: relative;
    display: flex;
    gap: 5px;
    width: 100%;
    align-items: center;
}


.list-entity-key input {
    padding: 18.5px 14px !important;
}

.list-entity-values{
    width: 100%;
}
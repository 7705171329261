/* div containers */
.response-table-container {
  padding-bottom: 10px;
}

.response-table-container .MuiInputBase-root {
  margin-top: 10px;
}

.response-code-container {
  margin-top: 15px;
}
.reply-action-container {
  margin-top: 10px;
}
.response-attributes-table-container {
  margin-top: 10px;
}

/* HTML Components */
.format-label {
  text-align: initial !important;
}


@media only screen and (max-width: 600px) {
  .top-left-label {
    position: relative;
    right: 0;
  }
}

.responseBranch__responseName
  > .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense {
  margin-top: 0px;
}

.responseAttributesTable__section--justify {
  justify-content: right;
}

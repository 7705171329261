.account-info-container {
    font-size: 17px;
    text-align: center;
    border: 1px solid #b2b2b2;
    border-radius: 10px;
}

.active-channels-container {
    border: 1px solid #b2b2b2;
    border-radius: 10px;
}

.active-channels-container :is(h2, p) {
    margin: 5px;
}
.active-channels-container-name {
    margin: 0;
    text-align: left;
}
.account-info-container :is(h3, p) {
    margin: 5px;
}

.my-account-container {
    width: 100%;
}
.my-account-actions {
    display: flex;
    justify-content: space-around;
}

.user-info-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 15px;
    background-color: #373788;
    border: 1px solid #373788;
    border-radius: 10px;
    color: white;
}

.user-info-container :is(h3, p, button) {
    margin: 5px 0;
    padding: 0;
}

.logout-icon {
    font-size: 2.5em !important;
    color: white;
}

.nav-link {
    color: white !important;
    font-weight: 700;
}

.suscription-perks-availability {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.suscription-perks-availability-container {
    display: flex;
}

/* accordion */
.accordion__summary-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 92px;
}
.accordion__details-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

/* channels */
.channels-container {
    display: flex;
    flex-direction: column;
}

.container--align-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container--content {
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    grid-template-columns: 25px 130px 50px;

}
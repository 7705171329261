.Tabs {
    background-color: #27315d;
    margin: 20px 0;
    border-radius: 20px;
}
.FAQsection {
    min-width: 0;
    color: white !important;
    text-transform: none !important;
    padding: 0px 10px !important;
}
.MuiTabs-indicator {
    background-color: #70c5d8 !important;
}
.footer {
    display: flex;
    justify-content: center;
}

.top-label-field {
    text-align: left !important;
    margin-top: 10px;
  }

.center-tooltip {
  display: flex;
  align-items: center;
  gap: 5px;
}
  
  .addNewBodyValue--margin {
    margin-top: 6px;
  }
  
  span.errorText {
    color: red;
    font-size: 0.75rem;
    margin-left: 17px;
  }
  
.template-header {
    display: flex;
    align-items: center;
    gap: 10px;
}

.gray-input {
    background-color: #d6d6d6;
    border: 1px solid #d6d6d6;
    border-radius: 5px;
}
.template-item {
    display: flex;
    align-items: flex-start;
    width: 100%;
}
.template-action-buttons-container {
    display: flex;
}

@media screen and (min-width: 0px) and (max-width: 499px) {
    .template-header {
        max-width: 78vw;
    }

    .template-header > h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.multimedia-template-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
}

.multimedia-template-iframe {
    width: min-content;
    height: 100%;
    border: none;
}

.multimedia-container {
    overflow: hidden;
    max-width: 90%;
    border: 2px dashed #ccc;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    text-overflow: ellipsis;
}

.multimedia-container-file {
    width: 100%;
    height: auto;
    border: 2px dashed #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    margin: 0 auto;
}

.file-link {
    text-decoration: none;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.file-icon {
    width: 90px;
    height: 100px;
    background-color: #fff;
    border-radius: 5px; /* Slightly rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.file-icon svg {
    width: 90px;
    height: auto;
}

.file-name {
    margin-top: 10px;
    word-break: break-all;
    color: #27315d; /* Dark text color */
}

.file-link p {
    margin: 0;
    font-size: 14px; /* Slightly larger text */
    color: #27315d; /* Medium gray text */
    width: 100%;
}

.multimedia-container-image {
    max-width: 100%;
    height: auto;
}

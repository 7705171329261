.bot-chat-container {
    position: fixed;
    width: 35vw;
    background-color: #fafafa;
    bottom: 0;
    right: 0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.slide-up-animation {
    animation-name: slide-up;
    animation-duration: 0.5s;
}

@keyframes slide-up {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}

.bot-chat-container.slide-up {
    animation: slide-up 0.5s ease-out;
}

.bot-chat-header {
    display: flex;
    justify-content: space-between;
    background-color: #27315d;
    color: white;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 20px;
    height: 60px;
    align-items: center;
    text-align: center;
}

.bot-chat-header .MuiIconButton-root {
    color: white;
}

.bot-chat-widget {
    height: 590px !important;
}

.permissions-selector{
    width: 200px;
}

.manage-permissions-new-user-container{
    margin: 10px 0;
    display: flex;
    align-items: center;
    gap: 10px;
}

.manage-permissions-new-email{
    width: 100%;
}

.manage-permissions-container{
    min-width: 50em;
}
.manage-permissions-table-container{
    width: 100%;
}

.manage-users-table-header{
    border: solid #B2B2B2 1px;
    display: flex;
}

.manage-users-header-label-border{
    padding: 0px;
    border-right: 1px solid #B2B2B2
}

.manage-users-header-label{
    padding: 0px;
}

.manage-users-row-container{
    display: flex;
    padding: 0px;
    align-items: center;
    border: 1px solid #B2B2B2;
}

.manage-users-page-header{
    display: flex;
}
.agregar-icono-circle{
    fill: #27315d;
}

.agregar-icono-cross {
    fill: #ffffff;
}

.icon-primary-color {
    fill: #27315d;
}
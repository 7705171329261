.apiOptions--styles {
  margin-top: 20px;
}

.element--alignFirstLevel {
  position: relative;
  top: 1vh;
  left: -4.5vw;
  padding-bottom: 35px;
}

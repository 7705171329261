.faq-test-user-message-container {
    background-color: #a3eaf7;
    padding: 15px;
    border-radius: 10px;
    max-width: 215px;
    position: relative; /* make the container positioned */
    word-break: break-word;
}

.faq-test-user-message-container::before {
    content: "";
    position: absolute; /* make the pseudo-element positioned */
    top: 15px; /* distance from top */
    right: -10px; /* distance from right */
    border-top: 10px solid transparent; /* top triangle */
    border-left: 10px solid #a3eaf7; /* left rectangle */
    border-bottom: 10px solid transparent; /* bottom triangle */
}

.faq-test-avatar-img{
object-fit: contain !important;
}


.faq-test-question-container{
    display: flex;
    gap: 15px;
    margin: 10px 10px 10px auto;
    align-items: baseline;
}

.faq-test-intebot-message-container {
    background-color: #e6e6e6;
    padding: 15px;
    border-radius: 10px;
    max-width: 215px;
    position: relative; /* make the container positioned */
    word-break: break-word;
}

.faq-test-intebot-message-container::before {
    content: "";
    position: absolute;
    top: 15px;
    left: -10px;
    border-top: 10px solid transparent;
    border-right: 10px solid #e6e6e6;
    border-bottom: 10px solid transparent;
}


.faq-test-answer-container{
    display: flex;
    gap: 15px;
    margin: 10px auto 10px 10px;
    align-items: baseline;
}


.faq-test-user-message{
    display: flex;
    flex-direction: column;
}

.faq-test-hour-question{
    font-size: 10px;
    margin-top: 5px;
    align-self: flex-end;
}

.faq-test-chat-container{
    min-height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.faq-test-chat-footer{
    display: flex;
    justify-content: center;
}

.faq-test-send-button{
    margin-right: 15px !important;
    margin-left: 25px !important;
}

.faq-test-container h1 {
    text-align: center;
}

.faq-test-container{
    margin-top: 25px;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #b2b2b2;
    width: 100%;
}


.styled-badge .MuiBadge-badge {
    box-shadow: 0 0 0 2px #fff;
}

.online-badge {
    background-color: #44b700;
    color: #44b700;
}

.busy-badge {
    background-color: #ff3d00;
    color: #ff3d00;
}

.offline-badge {
    background-color: #808080;
    color: #808080;
}
  
.styled-badge .MuiBadge-badge::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: ripple 1.2s infinite ease-in-out;
    border: 1px solid currentColor;
    content: "";
}

@keyframes ripple {
    0% {
    transform: scale(.8);
    opacity: 1;
    }
    100% {
    transform: scale(2.4);
    opacity: 0;
    }
}
#dropzone-faq {
    /* text-align: -webkit-center; */
}
.dropzoneContainerFaq {
    min-height: min-content !important;
    width: 22vw !important;
    padding: 15px;
    background-color: #fafafa !important;
}
.dropzoneContainerFaqWithError {
    min-height: min-content !important;
    width: 22vw !important;
    padding: 5px;
    border-color: red !important;
}

#dropzone-faq .MuiDropzonePreviewList-imageContainer {
    max-width: inherit;
}

.PLN-permanent-drawer{
    width: 200px;
}

.drawer-paper{
    width: 305px !important;
    z-index: 10 !important;
    top: 103px !important;
    margin-top: 5px;
    position: absolute !important;
}

.drawer-paper-mobile{
    width: 305px !important;
}

.menu-list-container{
    padding: 16px !important;
}

.menu-item-text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.open-mobile-pln-menu-btn{
    position: relative;
    right: 25px;
}
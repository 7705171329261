.menu-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 300px;
}

.my-account-icon{
    font-size: 3em !important;
}

.profile-name-container{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px;
    justify-content: center;
}

.profile-name-container :is(h1, h2, h3, h4, h5, h6) {
    margin: 0;
}
.sub-selector{
    min-width: 150px !important;
}

.sub-selector .MuiOutlinedInput-input {
    padding: 10.5px 14px;
}

.logout-button{
    position: relative;
    left: 5em;
}

.menu-button{
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}

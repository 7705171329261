.PLN-main-page{
    display: flex;
    align-items: flex-start;
}
.PLN-main-content-table{
    width: 100%;
}
.PLN-main-page :is(h1, h2, h3, h4, h5, h6) {
    margin: 0;
}
.test-query-element{
    border: #b2b2b2 1px solid;
}
.PLN-footer-page{
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 99;
    padding: 16px;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-self: center;
    background-color: #ffffff;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.publish-pln-btn {
    background-color: rgb(75, 163, 65) !important;
}
.disabled-publish-pln-btn{
    background-color: #b2b2b2 !important;
}


/* move PLN-main-page to the left when the media query is 600px */
@media (max-width: 600px) {
    .PLN-main-page{
        flex-direction: column;
    }
    .PLN-main-content-table{
        width: 90%;
    }
}

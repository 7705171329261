.center-loading-icon{
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.search-box-container{
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 83px;
    z-index: 999;
    background: #fafafa;
    width: 100%;
    padding: 16px;
    align-items: center;
}
.send-campaings-footer{
    display: flex;
    justify-content: flex-end;
    position: sticky;
    bottom: 0;
    z-index: 999;
    background: #fafafa;
    width: 100%;
    padding: 16px;
    align-items: center;
    background-color: #fafafa;
    border-top: 1px solid rgba(0, 0, 0, 0.12),
}

@media screen and (min-width: 0px) and (max-width: 499px) {
    .search-box-container{
        padding: 16px 0;
    }
}
.test-pln-container{
    margin-top: 25px;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #b2b2b2;
    width: 100%;
    height: 100%;
}

.test-query-table{
    min-height: 50vh;
}

.test-pln-chatbox{
    display: flex;
    padding: 10px;
}
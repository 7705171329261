a {
  text-decoration: none;
  color: #b2b2b2;
}
.intebotLogoContainer {
  width: 20em;
}
.linearProgressColorRed {
  background-color: #e42922 !important;
}
.linearProgressColorOrange {
  background-color: #e48922 !important;
}
.linearProgressColorGreen {
  background-color: #44ab3f !important;
}

/* Login Form */
.LoginMain {
  display: flex;
  flex-direction: column;
  min-height: calc(93vh - 150px);
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.LoginForm {
  border: 3px #b2b2b2 solid;
  border-radius: 20px;
  padding: 2em;
  min-width: 37em;
  min-height: 21em;
  margin: 1rem;
}
.LoginForm h2 {
  text-align: center;
  margin: 0;
}
.LoginForm h4 {
  text-align: center;
  margin: 10px 0;
}
.AuthInputsContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 1em;
}
.loginHelpContainer {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 0 2em;
}
.loginLinksContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.authButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

/* restore Account */
.restoreHelpContainer {
  display: flex;
  justify-content: center;
}

/* Create Account */
.input-w-label-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.container__notes {
  padding: 0 4px 3px;
}

@media screen and (min-width: 0px) and (max-width: 319px) {
  .LoginMain {
    min-height: 75vh;
  }

  .LoginForm {
    padding: 20px 10px;
    min-width: 10vw;
    min-height: 21em;
    margin: 1rem 0;
  }

  .intebotLogoContainer {
    width: 16em;
  }

  .loginLinksContainer {
    display: grid;
    text-align: center;
  }

  .loginHelpContainer {
    padding: 0 0.8em;
  }

  .loginLinksContainer > a {
    margin: 4px 0;
  }
}

@media screen and (min-width: 320px) and (max-width: 599px) and (max-height: 740px) {
  .LoginMain {
    min-height: 76vh;
  }

  .LoginForm {
    padding: 20px 10px;
    min-width: 10vw;
    min-height: 21em;
    margin: 1rem 0;
  }

  .intebotLogoContainer {
    width: 16em;
  }

  .loginHelpContainer {
    padding: 0 1.5em;
  }
}

@media screen and (min-width: 320px) and (max-width: 599px) and (max-height: 950px) {
  .LoginMain {
    min-height: 77vh;
  }

  .LoginForm {
    padding: 20px 10px;
    min-width: 10vw;
  }

  .intebotLogoContainer {
    width: 17em;
  }

  .loginHelpContainer {
    padding: 0 1.5em;
  }

  .loginLinksContainer > a:first-child {
    margin-right: 20px;
  }
}

@media screen and (min-width: 600px) and (max-width: 1024px) and (min-height: 720px) {
  .LoginMain {
    min-height: 82vh;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1124px) and (min-height: 1300px) {
  .LoginMain {
    min-height: 85vh;
  }
}

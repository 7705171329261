.edit-intents-actions{
    display: flex;
    width: 100%;
    gap: 10px;
    margin: 10px 0;
    padding: 10px 0;
    justify-content: space-evenly;
}
.intents-table-header{
    display: flex;
    background-color: #27315d;
    color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.example-content-container{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.example-content-container span {
    font-size: 12px;
    margin: 0 10px;
    color: #27315d;
}
.intents-table{
    width: 100%;
}
.empty-intents-table{
    height: 20px;
    width: 100%;
    background-color: white;
    border: 1px solid #B2B2B2;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.intent-table-component{
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid #B2B2B2;
    padding: 10px;
}

.radio-header{
    width: 42px;
    color: white !important;
}
.name-header{
    width: calc(100% - 60%);
    border-right: 1px solid #fafafa;
    padding: 5px;
    word-break: break-all;
}
.examples-header{
    width: calc(100% - 80%);
    display: flex;
    justify-content: center;
    border-right: 1px solid #fafafa;
    padding: 5px;
    word-break: break-all;
}
.features-header{
    width: calc(100% - 60%);
    padding: 5px;
    word-break: break-all;
}
.ejamples-menu-tree-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.select-entity-menu{
    padding: 0 10px;
    width: 350px;
}
.select-entity-menu h3 {
    margin: 5px 0;
}
.entity-list{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 10px;
    border: 1px solid #B2B2B2;
    border-radius: 10px;
    overflow-y: scroll;
    max-height: 200px;
    gap: 10px;
}

.entity-menu-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid #B2B2B2;
}


.entity-label{
    width: 100%;
    cursor: pointer;
}


.entity-label:hover{
    background-color: rgba(0, 0, 0, 0.04);
}

.entity-tree{

}

.MuiTreeItem-label{
    font-size: 14px !important;
}

/* change the font size for name-header with the media query of 960px */
@media (max-width: 960px) {
    .name-header{
        font-size: 14px;
    }
    .examples-header{
        font-size: 14px;
    }
    .features-header{
        font-size: 14px;
    }
    .MuiButton-label{
        font-size: 14px !important;
    }
}

/* change the font size for name-header with the media query of 600px */
@media (max-width: 600px) {
    .name-header{
        font-size: 12px;
    }
    .examples-header{
        font-size: 12px;
    }
    .features-header{
        font-size: 12px;
    }
    .MuiTreeItem-label{
        font-size: 12px !important;
    }
    .MuiButton-label{
        font-size: 12px !important;
    }
}
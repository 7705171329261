.frame {
    overflow: hidden;
    height: calc(100vw * 0.42);
    width: 100%;
}

.downloadContainer {
    width: 70%;
}

.downloadContainerInput {
    display: flex;
    gap: 10px;
    margin: 5px 0;
    flex-direction: column;
    width: 100%;
}

.downloadContainerFooter {
    display: flex;
    position: fixed;
    bottom: 0;
    flex: 0 0 auto;
    width: 100%;
    left: 0;
    z-index: 99;
    background-color: white;
    padding: 16px; /* Assuming theme.spacing(2) is 16px */
    margin: 0;
    align-items: center;
    justify-content: center;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}
